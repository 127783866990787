import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services';

@Injectable()
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate() {
    if (AuthService.isAuthenticated()) {
      // Injects respond.io chat at Latintours environment.
      const user = AuthService.getUser();
      // eslint-disable-next-line no-underscore-dangle
      window.__respond_settings = {
        countryCode: '',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        custom_fields: {
          agency: user.NombreAgencia,
          sivuelasAgencyId: user.IdAgencia,
          sivuelasUserId: user.IdUsuario
        },
        email: user.EmailUsuario,
        firstName: user.NombreUsuario,
        identifier: user.IdUsuario,
        language: user.Idioma,
        lastName: `(${user.NombreAgencia})`,
        phone: user.TelefonoInterno,
        profilePic: user.UrlLogoAgencia,
      };
      const webchat = document.getElementById('respondio__widget');
      webchat?.setAttribute(
        'src',
        'https://cdn.respond.io/webchat/widget/widget.js?cId=5c0036d5d9d5760156ed94dd911b247'
      );

      // Show respond.io chat at Latintours environment.
      let webchatElement: HTMLElement;
      const iframes = document.querySelectorAll('iframe');
      iframes.forEach(el => {
        if(el.src.includes('cdn.respond.io')) {
          webchatElement = el;
        };
      });
      if (webchatElement) {
        webchatElement.style.display = 'block';
      }

      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
