///////////////////////////////////////////////////////////// PRE BOOMERANG
import packageJson from '../../../../package.json';

export const environment = {
  brand: 'latintours',
  production: false,
  domain: 'https://latintours.navisk.com',
  domainStatics: 'https://webapitestLatintours.navisk.com',
  apidomain: 'https://webapitestLatintours.navisk.com/api/',
  googlekey: null,
  youtubeList: null,
  recaptchakey: '6LcKYOUUAAAAAPiqu-Zke2vovQSYBztEKTb6qXi9',
  googleSiteVerification: '7thXmbmaTL41JjfSUh-KtDjagJzZSTBsNI5Bw8xEKkk',
  chat: {
    api: null,
    socket: null,
  },
  defaultLang: 'es',
  myMessages: true,
  allowedVideo: false,
  allowedManagerUsers: true,
  version: packageJson.version
};
